import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form, Modal } from 'react-bootstrap';
import foto from '../assets/img/photo_contact.png';
import tel from '../assets/img/icn_phone.png';
import sobre from '../assets/img/icn_mail.png';
import correoExito from '../assets/img/icn_sent.png';
import correoError from '../assets/img/icn_failed.png';
import '../assets/css/Contacto.css';
import { MDBInput } from "mdbreact";

class ContentContacto extends Component {
  constructor(props, context) {
    super(props, context);

    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);

    this.state = {
      exito: false,
      error: false,
    };
  }

  // handleClose() {
  //   this.setState({ error: false });
  // }

  // handleShow() {
  //   this.setState({ error: true });
  // }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('./sendMail.php', {
      method: 'POST',
      body: data,
    })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        this.setState({ exito: true });
        setTimeout(() =>{ 
          this.setState({ exito: false }); 
          window.location.reload();
        }, 3000);
        
      }
      else{
        this.setState({ error: true });
        setTimeout(() =>{ this.setState({ error: false }); }, 3000);
      }
    })
    .catch((error) => {
      console.log('Hubo un problema con la petición Fetch:' + error.message);
      this.setState({ error: true });
      setTimeout(() =>{ this.setState({ error: false }); }, 3000);
    });
  }

  render() {
    return (
      <div className="contacto">
        <div className="contentContacto">
          <Row>
            <Col xs="5" className="img">
              <img src={foto} />
            </Col>
            <Col xs="12" md="7" lg={{ span: 5, offset: 1 }}>
              <h2>Contáctanos</h2>
              <h3>Envíanos un mensaje</h3>
              <p>Con gusto te atenderemos</p>
              <Form onSubmit={this.handleSubmit} id="form">
                <Row>
                  <Col xs="12" md="6">
                    <Form.Group>
                      {/* <Form.Control type="text" placeholder="Nombre" name="nombre" id="nombre"/> */}
                      <MDBInput label="Nombre" type="text" name="nombre" id="nombre" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="email" label="Correo" name="correo" id="correo" required/>
                    </Form.Group>
                  </Col>
                   <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="tel" label="Teléfono" name="tel" id="tel" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="text" label="Asunto" name="asunto" id="asunto" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <Form.Group>
                      <MDBInput type="textarea" rows="3" label="Mensaje" name="mensaje" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <Form.Group>
                      <div className="g-recaptcha" data-sitekey="6LcIgscUAAAAAB_gtX5FMnQcupu9s0cHmdhqTiip"></div>
                    </Form.Group>
                  </Col>
                </Row>
                <div class="pot">
                  <MDBInput type="text" label="website" name="website" id="website"/>
                </div>
                <MDBInput type="hidden" name="contacto" value="contacto"/>
                <Button variant="primary" type="submit">Enviar</Button>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="content datos">
          <Row>
            <Col xs="12" lg={{ span: 10, offset: 1 }}>
              <Row>
                <Col xs="12" md="6" lg="5" className="datosContacto">
                  <h2>Contáctanos</h2>
                  <strong><img src={tel} />(686) 558 6335</strong>
                  <div className="clear1"></div>
                  <strong><img src={tel} className='tel2'/>(686) 557 1681</strong>
                  <div className="clear1"></div>
                  <strong><img src={sobre} />ventas@quiasa.com</strong>
                  <div className="clear2"></div>
                  <small>Copyright @ Developed by QDC</small>
                </Col>
                <Col xs="12" md="6" lg="7">
                  <h2>Nuestras ubicaciones</h2>
                  <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.6454981424704!2d-115.40990781795226!3d32.668876594703384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d771c13253809b%3A0xb4fda6a59f35cee2!2sQuimica+Industrial+y+Analitica+SA+de+CV!5e0!3m2!1ses!2smx!4v1556231516566!5m2!1ses!2smx"></iframe>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <Button variant="primary" onClick={() => this.handleShow('Incubadora Incucell 55 precio especial')}>Contáctanos</Button> */}
        <Modal show={this.state.exito} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoExito} />
            <h3>¡Gracias!</h3>
            <p>Tu mensaje ha sido enviado</p>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.error} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoError} />
            <h3>Lo sentimos</h3>
            <p>Ha ocurrido un problema al intentar enviar tu correo, inténtalo más tarde</p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ContentContacto;
