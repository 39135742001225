import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import producto1 from '../assets/img/productos/product.png';
import carrito from '../assets/img/icn_cart_white.png';
import descargar from '../assets/img/icn_download.svg';
import '../assets/css/Producto.css';
import Footer from './Footer'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actualizarCarrito from '../redux/actions/actualizarCarrito'
import * as totalCarrito from '../redux/actions/totalCarrito';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

class Producto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: '',
      descripcion: '',
      precio: '',
      imagen: '',
      nombreImagen: '',
      cantidad: 1
    }
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
    const { id } = this.props.match.params
    const json = require('./dlab.json');
    json.map(producto => {
      const prodImage = require(`../assets/img/productos/dlab/${producto.imagen}`);
      if(producto.codigo == id){
        this.setState({codigo: producto.codigo})
        this.setState({descripcion: producto.descripcion})
        this.setState({precio: producto.precio})
        this.setState({imagen: prodImage})
        this.setState({nombreImagen: producto.imagen})
      }
    })
  }

  cantidad = () => {
    this.setState({cantidad: document.getElementById("cantidad").value})
  }

  cotizar = (codigo, descripcion, precio, imagen, cantidad) => {
    const datos = {
      codigo: codigo,
      descripcion: descripcion,
      precio: precio,
      imagen: imagen,
      cantidad: parseInt(cantidad)
    }
    this.props.actualizarCarrito(datos);
    setTimeout(() => { this.cantidadTotal(); }, 200);
  }

  cantidadTotal = () => {
    const { resultCarrito } = this.props;
    var total = 0
    resultCarrito.map(producto => {
        total += parseInt(producto.cantidad)
    })
    this.props.totalCarrito(total);
  }
  
  render() {
    const images = [
      {
        original: this.state.imagen,
        thumbnail: this.state.imagen,
      },
      // {
      //   original: producto1,
      //   thumbnail: producto1
      // },
      // {
      //   original: producto1,
      //   thumbnail: producto1
      // }
    ]
    return (
      <div className="body">
        <div className="producto">
          <div className="content">
            <Row className="contentProd">
              <Col xs="12" lg="6">
                <ImageGallery items={images} thumbnailPosition="left" showPlayButton="false" showFullscreenButton="false" lazyLoad="true" />
              </Col>
              <Col xs="12" lg={{ span: 6 }} xl={{ offset: 1 }} className="info" >
                <strong>{this.state.descripcion}</strong>
                <p className="precio">{this.state.precio}</p>
                <h4>Código del producto:</h4>
                <p>{this.state.codigo}</p>
                {/* <h4>Descripción del producto:</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu leo sit amet felis imperdiet feugiat id a sapien. Aliquam sed nisi a dui consectetur maximus sed eu arcu. Nullam in leo molestie, placerat lectus vitae, cursus diam. Sed maximus lacinia viverra. Quisque vel tellus vitae ante ultrices aliquam vitae ac quam.</p> */}
                {/* <a href="#!"><img src={descargar} />Descargar fact sheet del producto</a> */}
                <Row className="agregar">
                  <Col xs="auto">
                    <Form>
                      <Form.Group controlId="cantidad">
                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control as="select" onChange={() => this.cantidad()}>
                          <option value="1">1 unidad</option>
                          <option value="2">2 unidades</option>
                          <option value="3">3 unidades</option>
                          <option value="4">4 unidades</option>
                          <option value="5">5 unidades</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col xs="auto">
                    <Button variant="primary" onClick={() => this.cotizar(this.state.codigo, this.state.descripcion, this.state.precio, this.state.nombreImagen, this.state.cantidad)}><img src={carrito} />Añadir al carrito de cotizaciones</Button>
                  </Col>
                </Row>
                <Row className="notas">
                  <Col xs="12">
                    <small>* Precios no incluyen IVA</small>
                  </Col>
                  <Col xs="12">
                    <small>* Los precios pueden variar sin previo aviso</small>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resultCarrito: state.items,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actualizarCarrito, ...totalCarrito}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Producto);
