import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import '../assets/css/Nosotros.css';
import Footer from './Footer'
import aboutus from '../assets/img/img_about.jpg';

class Nosotros extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="body">
        <div className="nosotros nosotros2 fondo">
          <div className="content">
            <Row>
              <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                <h3>Nosotros</h3>
                <hr/>
                <p>Nuestra empresa, fundada en 2002, se especializa en la comercialización de productos químicos, material para laboratorio, venta y servicio de bombas dosificadoras (líquidos y gases), venta de equipo y material para albercas, con esto queremos cubrir las necesidades de nuestros clientes, así como dar un servicio integral ayudando en la mejora continua de sus procesos.</p>
                <img src={aboutus}/>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg={{ span: 10, offset: 1 }}>
                <Row className="datos">
                  <Col xs="12" md="6" className="mision">
                    <h3>Misión</h3>
                    <p>Buscar el posicionamiento y satisfacer el mercado regional de una manera integral, con productos químicos, material y equipo de laboratorio de primera calidad, cumpliendo en tiempo y forma, con los requerimientos y especificaciones de nuestros clientes, impulsando el desarrollo sustentable.</p>
                  </Col>
                  <Col xs="12" md="6">
                    <h3>Visión</h3>
                    <p>Ser líderes en la distribución de productos químicos, material y equipo de laboratorio y sus productos complementarios a nivel nacional. Con el único fin de buscar continuamente la satisfacción integral de nuestros clientes.</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="valores">
            <Col xs="12" lg={{ span: 8, offset: 2 }}>
              <div className="content">
                <h3>Nuestros Valores</h3>
                <hr/>
                <Row className="puntos">
                  <Col xs="12" md="4">
                    <ul>
                      <li>Actitud</li>
                      <li>Compañerismo</li>
                      <li>Competitividad</li>
                      <li>Compromiso</li>
                    </ul>
                  </Col>
                  <Col xs="12" md="4">
                    <ul>
                      <li>Comunicación</li>
                      <li>Dedicación</li>
                      <li>Disciplina</li>
                      <li>Integridad</li>
                    </ul>
                  </Col>
                  <Col xs="12" md="4">
                    <ul>
                      <li>Orientación</li>
                      <li>Respeto</li>
                      <li>Responsabilidad</li>
                      <li>Trabajo en equipo</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="ofrecemos">
            <Col xs="12" lg={{ span: 8, offset: 2 }}>
              <div className="content">
                <h3>¿Qué ofrecemos?</h3>
                <hr/>
                <Row>
                  <Col xs="12" md="6" lg={{ span: 5, offset: 1 }}>
                    <ul>
                      <li>Atención personalizada</li>
                      <li>Respuesta oportuna</li>
                      <li>Capacidad de Almacenaje/Stock</li>
                      <li>Capacidad de Logística</li>
                      <li>Conocimiento / Asesoría</li>
                      <li>Distribuciones de Marcas</li>
                    </ul>
                  </Col>
                  <Col xs="12" md="6">
                    <ul>
                      <li>Seguridad Comercial</li>
                      <li>Atención a Clientes / Servicio Post-venta</li>
                      <li>Calidad de materiales</li>
                      <li>Precio Competitivo</li>
                      <li>Contratos con Clientes</li>
                      <li>Certificaciones de Mercancías</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Nosotros;
