import React, { Component } from 'react';
import { Row, Col, Button, Form, Tabs, Tab, Table, Modal } from 'react-bootstrap';
import producto1 from '../assets/img/productos/product.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as eliminarCarrito from '../redux/actions/eliminarCarrito';
import * as cantidadCarrito from '../redux/actions/cantidadCarrito';
import * as vaciarCarrito from '../redux/actions/vaciarCarrito';
import * as totalCarrito from '../redux/actions/totalCarrito';
import correoExito from '../assets/img/icn_sent.png';
import correoError from '../assets/img/icn_failed.png';
import { MDBInput } from "mdbreact";
import '../assets/css/Carrito.css';
import Footer from './Footer'

class Carrito extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "carrito",
      btnContinuar: false,
      btnDatos: true,
      exito: false,
      error: false,
    };
  }

  eliminar = (index) => {
    this.props.eliminarCarrito(index); 
    setTimeout(() => { this.cantidadTotal(); }, 200);
    setTimeout(() => { this.disabled(); }, 200);
  }

  vaciar = () => {
    this.props.vaciarCarrito(); 
    setTimeout(() => { this.cantidadTotal(); }, 200);
    setTimeout(() => { this.disabled(); }, 200);
  }

  cantidad = (index) => {
    const data = {
      index: index,
      cantidad: document.getElementById(index).value
    }
    this.props.cantidadCarrito(data);
    this.cantidadTotal();
  }

  cantidadTotal = () => {
    const { resultCarrito } = this.props;
    var total = 0
    resultCarrito.items.map((producto, index) => {
        total += parseInt(producto.cantidad)
    })
    this.props.totalCarrito(total);
  }


  componentDidMount = () =>{
    window.scrollTo(0, 0)
    this.cantidadTotal();
    this.disabled();
  }

  disabled = () =>{
    const { resultCarrito } = this.props;
    if(resultCarrito.carrito <= 0){
      this.setState({btnContinuar: true})
      this.setState({btnDatos: true})
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('./sendMail.php', {
      method: 'POST',
      body: data,
    })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        this.setState({ exito: true });
        setTimeout(() =>{ 
          this.setState({ exito: false }); 
          this.vaciar();
          window.location.reload();
        }, 3000);
      }
      else{
        this.setState({ error: true });
        setTimeout(() =>{ this.setState({ error: false }); }, 3000);
        document.getElementById("form").reset();
      }
    })
    .catch((error) => {
      console.log('Hubo un problema con la petición Fetch:' + error.message);
      this.setState({ error: true });
      setTimeout(() =>{ this.setState({ error: false }); }, 3000);
    });
  }

  render() {

    const { resultCarrito } = this.props;

    return (
      <div className="body">
        <div className="carrito">
          <div className="content">
            <Form onSubmit={this.handleSubmit} id="form">
              <Tabs id="uncontrolled-tab-example" activeKey={this.state.key} onSelect={key => this.setState({key: key})}>
                <Tab eventKey="carrito" title="Carrito de cotizaciones">
                  <Row className="contentCarrito">
                    <Col xs="12">
                      <h3>Carrito de cotizaciones</h3>
                      <p className="nota">* Precios sujetos a cambio</p>
                    </Col>
                    <Col xs="12" lg="8">
                      <div className="prod">
                        <Table >
                          <thead>
                            <tr>
                              <th>
                                <strong>Productos</strong>
                              </th>
                              <th className="thPrecio">
                                <strong>Precio estándar</strong>
                              </th>
                              <th>
                                <strong>Cantidad</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {resultCarrito.items.map((producto, index) => {
                              const prodImage = require(`../assets/img/productos/dlab/${producto.imagen}`);
                              return ( 
                                <tr key={producto.codigo}>
                                  <td>
                                  <Form.Control type="hidden" name="codigo[]" value={producto.codigo}></Form.Control>
                                    <Row>
                                      <Col xs="5" md="4" className="imagen">
                                        <img src={prodImage}/>
                                      </Col>
                                      <Col xs="7" md="8">
                                        <h4>{producto.descripcion}</h4>
                                        <Form.Control type="hidden" name="descripcion[]" value={producto.descripcion}></Form.Control>
                                        <p>DLAB</p>
                                        <p className="stock">En Stock</p>
                                        <Button className="eliminar" onClick={() => this.eliminar(index)}>Eliminar</Button>
                                        {/* <Button href="#!" className="comparar">Comparar</Button> */}
                                      </Col>
                                    </Row>
                                  </td>
                                  <td className="precioEstandar">
                                    {producto.precio}
                                    <MDBInput type="hidden" name="precio[]" value={producto.precio}></MDBInput>
                                  </td>
                                  <td className="cantidad">
                                    <Form.Group controlId={index}>
                                      <Form.Control as="select" name="cantidad[]" onChange={() => this.cantidad(index)}>
                                        <option value={producto.cantidad} hidden>{producto.cantidad} unidad(es)</option>
                                        <option value="1">1 unidad</option>
                                        <option value="2">2 unidades</option>
                                        <option value="3">3 unidades</option>
                                        <option value="4">4 unidades</option>
                                        <option value="5">5 unidades</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </td>
                                </tr>
                              )
                            })}
                          
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan="3"><h5>Total de productos: </h5><span>{resultCarrito.carrito}</span></th>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Col>
                    <Col xs="12" md={{ span:  6, offset: 3 }} lg={{ span:  4, offset: 0 }}>
                      <div className="subtotal">
                        <h5>Total de productos</h5>
                        <h5><span>{resultCarrito.carrito}</span></h5>
                        <Button variant="primary" onClick={() => {this.setState({key: "datos"}); this.setState({btnDatos: false})}} disabled={this.state.btnContinuar} >Continuar</Button>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="datos" title="Datos para cotización" disabled={this.state.btnDatos}>
                  <Row className="datos">
                    <Col xs="12" lg={{span:10, offset:1}}>
                      <Row className="contentDatos">
                        <Col xs="12">
                          <h3>Datos para cotización</h3>
                          <p>Datos personales</p>
                        </Col>
                        <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="text" name="nombre" label="Nombre" required/>
                          </Form.Group>
                        </Col>
                        <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="text" name="apellido" label="Apellido" required/>
                          </Form.Group>
                        </Col>
                        <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="email" name="correo" label="Correo" required/>
                          </Form.Group>
                        </Col>
                         <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="tel" name="tel" label="Teléfono" required/>
                          </Form.Group>
                        </Col>
                        <Col xs="12">
                          <p>Datos de empresa</p>
                        </Col>
                        <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="text" name="nombreEmpresa" label="Nombre" required/>
                          </Form.Group>
                        </Col>
                        <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="email" name="correoEmpresa" label="Correo" required/>
                          </Form.Group>
                        </Col>
                         <Col xs="12" md="4">
                          <Form.Group controlId="formBasicPassword">
                            <MDBInput type="tel" name="telEmpresa" label="Teléfono" required/>
                          </Form.Group>
                        </Col>
                        <Col xs="12">
                          <Form.Group>
                            <div className="g-recaptcha" data-sitekey="6LcIgscUAAAAAB_gtX5FMnQcupu9s0cHmdhqTiip"></div>
                          </Form.Group>
                        </Col>
                        <div class="pot">
                          <MDBInput type="text" label="website" name="website" id="website"/>
                        </div>
                      </Row>
                      <MDBInput type="hidden" name="carrito" value="carrito"/>
                      <Button variant="primary" type="submit">Enviar a cotización</Button>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Form>
          </div>
        </div>
        <Modal show={this.state.exito} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoExito} />
            <h3>¡Gracias!</h3>
            <p>Tu mensaje ha sido enviado</p>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.error} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoError} />
            <h3>Lo sentimos</h3>
            <p>Ha ocurrido un problema al intentar enviar tu correo, inténtalo más tarde</p>
          </Modal.Body>
        </Modal>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resultCarrito: state,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...eliminarCarrito, ...vaciarCarrito, ...cantidadCarrito, ...totalCarrito}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Carrito);
