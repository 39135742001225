// Dependencies
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, HashRouter, Link  } from "react-router-dom";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../redux/store'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import MessengerCustomerChat from 'react-messenger-customer-chat';

// Components
import Header from './Header'
import Home from './Home'
import Nosotros from './Nosotros'
import Cotiza from './Cotiza'
import Producto from './Producto'
import Carrito from './Carrito'
import Catalogos from './Catalogos'
import Contacto from './Contacto'


class App extends Component {
  
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <Header/>
            <Route path="/" exact component={Home}/>
            <Route path="/Nosotros" component={Nosotros} />
            <Route path="/Cotiza" component={Cotiza} />
            <Route path="/Producto/:id" component={Producto} />
            <Route path="/Carrito" component={Carrito} />
            <Route path="/Catalogos" component={Catalogos} />
            <Route path="/Contacto" component={Contacto} />
          </HashRouter>
        </PersistGate>
        <MessengerCustomerChat
          pageId="576852002330054"
          appId=""
        />
      </Provider>
    );
  }
}

export default App;
