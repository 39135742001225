import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import '../assets/css/Catalogos.css';
import Footer from './Footer'

// IMG
import img0 from '../assets/img/catalogo/img_catalogo_productos.jpg';
import img1 from '../assets/img/catalogo/img_catalogo1.jpg';
import img2 from '../assets/img/catalogo/img_catalogo2.jpg';
import img3 from '../assets/img/catalogo/img_catalogo3.jpg';
// import img4 from '../assets/img/catalogo/img_catalogo4.jpg';
import img5 from '../assets/img/catalogo/img_catalogo5.jpg';
// import img6 from '../assets/img/catalogo/img_catalogo6.jpg';
import img7 from '../assets/img/catalogo/img_catalogo_fisher.jpg';
import img8 from '../assets/img/catalogo/img_catalogo8.jpg';
import img9 from '../assets/img/catalogo/img_catalogo9.jpg';
import img10 from '../assets/img/catalogo/img_catalogo10.jpg';
import img11 from '../assets/img/catalogo/img_catalogo11.jpg';
import img12 from '../assets/img/catalogo/img_catalogo_sensafe.jpg';
import img13 from '../assets/img/catalogo/img_catalogo_carus.jpg';
// import img14 from '../assets/img/catalogo/img_catalogo_chemtool.jpg';
import img15 from '../assets/img/catalogo/img_catalogo_tci.jpg';
import img16 from '../assets/img/catalogo/img_catalogo_cdg.jpg';
import img17 from '../assets/img/catalogo/img_catalogo_janvi.jpg';
import img18 from '../assets/img/catalogo/img_catalogo_columbia.jpg';

// LOGOS
import aquaclean from '../assets/img/logos/logo_aquaclean.png';
import adam from '../assets/img/logos/logo_adam.png';
import biologix from '../assets/img/logos/logo_biologix.png';
// import biomiga from '../assets/img/logos/logo_biomiga.png';
import condat from '../assets/img/logos/logo_condat.png';
// import dlab from '../assets/img/logos/logo_dlab.png';
import fisher from '../assets/img/logos/logo_fisher.png';
import jalmek from '../assets/img/logos/logo_jalmek.png';
import milton from '../assets/img/logos/logo_milton.png';
import injecta from '../assets/img/logos/logo_injecta.png';
import taylor from '../assets/img/logos/logo_taylor.png';
import sensafe from '../assets/img/logos/logo_sensafe.png';
import carus from '../assets/img/logos/logo_carus.png';
// import chemtool from '../assets/img/logos/logo_chemtool.png';
import tci from '../assets/img/logos/logo_tci.png';
import cdg from '../assets/img/logos/logo_cdg.png';
import janvi from '../assets/img/logos/logo_janvi.png';
import columbia from '../assets/img/logos/logo_columbia.png';

// CATÁLOGOS
import catalogoTaylor1 from '../assets/catalogos/Taylor-Industrial-Commercial-Catalog.pdf';
import catalogoTaylor2 from '../assets/catalogos/Taylor-Testing-Products-Selection-Guide.pdf';
// import catalogoDlab from '../assets/catalogos/DLAB-Catalogue.pdf';

class Catalogos extends Component {
  pdf = (pdf1, pdf2) => {   
    window.open(pdf1, "_blank")
    window.open(pdf2, "_blank")
  }
  componentDidMount(){
    window.scrollTo(0, 11)
  }
  render() {
    return (
      <div className="body">
        <div className="catalogos">
          <div className="content">
            <Row className="catalogo">
              <Col xs="12" lg={{span:10, offset:1}}>
                <h3>Distribuidores Exclusivos de Zona Noroeste</h3>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img7}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={fisher}/>
                    <p>Material, accesorios y equipos para laboratorio</p>
                    <Button variant="primary" href="https://www.fishersci.com/us/en/home.html" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}} className="img">
                    <img src={img5}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    {/* <img src={adam}/> */}
                    <img src={condat}/>
                    <p>CONDAT desarrolla, fabrica y vende una gama completa de lubricantes PROCESS destinados al trabajo de metales. Nuestros lubricantes están desarrollados para brindar beneficios a los usuarios, pero también para mejorar las condiciones de trabajo y minimizar el impacto ambiental.</p>
                    <Button variant="primary" href="https://www.condat-lubricants.com/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img18}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={columbia}/>
                    <p>Columbia Chemical es líder mundial en el desarrollo de aditivos para acabado de superficies. Esta línea maneja productos relacionados a Platinados, pre tratamientos y aditivos como abrillantadores, surfactantes y más.</p>
                    <Button variant="primary" href="https://www.columbiachemical.com/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}} className="img">
                    <img src={img0}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    {/* <img src={adam}/> */}
                    <strong>Productos Químicos Industriales</strong>
                    <p>Manejamos productos químicos industriales como, ácido muriático, ácido nítrico, ácido fosfórico, ácido sulfúrico, hipoclorito de sodio (Cloro líquido), hipoclorito de calcio, alcohol isopropílico, hexano, metil etil cetona, mineral spirit, percloroetileno, sosa líquida 50% y thiner laquer. </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img16}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={cdg}/>
                    <p>El dióxido de cloro es excepcionalmente eficiente en la destrucción de biopelículas, una matriz de limo y microorganismos que se forma dentro de las tuberías de agua y causa la exposición humana a patógenos dañinos. El dióxido de cloro no solo puede prevenir la creación de biopelículas, sino que también puede destruir y erradicar incluso los depósitos de biopelículas más gruesos y establecidos.</p>
                    <Button variant="primary" href="https://cdgenvironmental.com/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}} className="img">
                    <img src={img8}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={jalmek}/>
                    <p>Productos químicos analíticos nacionales.</p>
                    <Button variant="primary" href="http://www.jalmek.com/catalogo.html#V" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img1}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                  <img src={aquaclean}/>
                    <p>Hipoclorito de calcio granulado y en tableta NSF</p>
                    {/* <Button variant="primary" href="#!">Ver catálogo</Button> */}
                  </Col>
                </Row>
                <h3>Marcas que Manejamos</h3>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}} className="img">
                    <img src={img2}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={adam}/>
                    <p>Balanzas para laboratorio, granatarias, analíticas</p>
                    <Button variant="primary" href="http://lasallescientific.com/wp-content/uploads/2018/12/Adam-Equipment-Catalogue.pdf" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img3}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={biologix}/>
                    <p>Puntillas y tubos para centrifuga varias medidas</p>
                    {/* <Button variant="primary" href="#!">Ver catálogo</Button> */}
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}} className="img">
                    <img src={img4}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={biomiga}/>
                    <p>Productos para biomedicina y farmacéuticas DNA/RNA</p>
                    <Button variant="primary" href="http://www.hohoteco.idv.tw/Files/Biomiga%20Featured%20Products%20Catalog.pdf" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img6}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={dlab}/>
                    <p>Productos para laboratorio</p>
                    <Button variant="primary" href={catalogoDlab} target="_blank">Ver catálogo</Button>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}}className="img">
                    <img src={img9}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={milton}/>
                    <p>Bombas de dosificación química y controladores</p>
                    <Button variant="primary" href="http://www.phoenixindustrial.com/pdf/LMI_MeteringPump.pdf" target="blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img10}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={injecta}/>
                    <p>Bombas de dosificación química y controladores</p>
                    <Button variant="primary" href="http://www.injecta.com/qrcode/ES/WaterTreatment.pdf" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}}className="img">
                    <img src={img11}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={taylor}/>
                    <p>Sistemas de dosificación para tratamiento de agua, kits de prueba de agua y reactivos para los sectores de piscinas / spas e industriales.</p>
                    <Button variant="primary" onClick={() =>this.pdf(catalogoTaylor1, catalogoTaylor2)}>Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img12}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={sensafe}/>
                    <p>SenSafe® es la marca de producto insignia de Industrial Test Systems (ITS). Nuestras tiras reactivas patentadas SenSafe® son las más precisas, sensibles, asequibles y fáciles de usar del mercado. Han revolucionado la forma en que se realizan las pruebas de agua en la actualidad.</p>
                    <Button variant="primary" href="https://sensafe.com/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}}className="img">
                    <img src={img13}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={carus}/>
                    <p>En Carus Group Inc. fabricamos productos de máxima calidad ayudando a nuestros clientes en la solución de problemas relacionados con el medioambiente. Nuestra visión refleja un compromiso a largo plazo de servicio a nuestros clientes.</p>
                    <Button variant="primary" href="http://es.www.caruscorporation.com/water/products" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}}className="img">
                    <img src={img14}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={chemtool}/>
                    <p>Los fluidos de ingeniería de Chemtool Incorporated son una opción ideal para instalaciones de mecanizado de aceros al carbono y de peaje, hierro fundido y nodular, aleaciones de acero inoxidable, aleaciones de aluminio y aleaciones de cobre / latón.</p>
                    <Button variant="primary" href="https://www.chemtool.com/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:1}} className="img">
                    <img src={img15}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:2}} xl={{span:5, offset:1}}>
                    <img src={tci}/>
                    <p>Tokyo Chemical Industry Co., Ltd. (TCI) es un fabricante líder mundial de productos químicos orgánicos especializados fundado en 1946. TCI proporciona productos químicos orgánicos de laboratorio, así como materiales farmacéuticos, cosméticos y funcionales. Más de 70 años de experiencia en síntesis y plantas multipropósito permiten a TCI ofrecer más de 30.000 productos, así como síntesis personalizada.</p>
                    <Button variant="primary" href="https://www.tcichemicals.com/MX/en/" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span:12, order:1}} md={{span:6, order:2}} xl={{offset:1}}className="img">
                    <img src={img17}/>
                  </Col>
                  <Col xs={{span:12, order:2}} md={{span:6, order:1}} xl={{span:5}}>
                    <img src={janvi}/>
                    <p>Nuestro Core Business está enfocado a la Comercialización de Productos Químicos, Aditivos Alimenticios, Colorantes Naturales y Artificiales, Lacas Alumínicas y Saborizantes, para la Industria Alimenticia, Cosmética, Farmacéutica y de Plásticos.</p>
                    <Button variant="primary" href="http://janvicolors.com.mx/default.html" target="_blank">Ver catálogo</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Catalogos;
