import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import logo from '../assets/img/logo_quiasa.png';
import tel from '../assets/img/icn_phone.png';
import sobre from '../assets/img/icn_mail.png';
import '../assets/css/Footer.css';

class Footer extends Component {
  render() {
    return (
    	<div className="footer">
    		<div className="azul">
    			<div className="content">
    				<Row>
	    				<Col xs="12" md="4" className="logo">
	    					<img src={logo}/>
	    				</Col>
	    				<Col xs="12" md="8" className="datos">
	    					<p><img src={tel} />(686) 558 6335 </p>
	    					<p><img src={sobre} />ventas@quiasa.com</p>
	    				</Col>
	    			</Row>
    			</div>
    		</div>
    		<div className="blanco">
    			<div className="content">
    				<small>Copyright @ Developed by QDC</small>
    			</div>
    		</div>
        </div>
    );
  }
}

export default Footer;
