const defaultState = {
    items: [],
    carrito: 0
};

export default function resultCarrito(state = defaultState, action) {
    switch (action.type) {
        case "agregar":
            var x = 0;
            var posicion;
            {state.items.map((producto, index) => {
                if(producto.codigo == action.payload.codigo){
                   x++;
                    posicion = index;
                }
            })}
            if(x == 0){
                const newItems = state.items.slice();
                newItems.push(action.payload);
                return { ...state, items: newItems };
            }
            else{
                const newItems = state.items.slice();
                newItems[posicion].cantidad += action.payload.cantidad;
                return { ...state, items: newItems };
            }
        case "eliminar":
            const newItems = state.items.slice();
            newItems.splice(action.payload, 1);
            return { ...state, items: newItems };
        case "vaciar":
            const newVacio = [];
            return { ...state, items: newVacio };
        case "cantidad":
            const nItems = state.items.slice();
            nItems[action.payload.index].cantidad = action.payload.cantidad;
            return { ...state, items: nItems };
        case "total":
            var newCarrito = action.payload;
            return { ...state, carrito: newCarrito };

        default:
            return state;
    }
}