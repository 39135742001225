import React, { Component } from 'react';
import Slider from "react-slick";
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import cliente1 from '../assets/img/clientes/cliente_1gob.jpg';
import cliente2 from '../assets/img/clientes/cliente_2sep.jpg';
import cliente3 from '../assets/img/clientes/cliente_3pjbc.jpg';
import cliente4 from '../assets/img/clientes/cliente_4conagua.jpg';
import cliente5 from '../assets/img/clientes/cliente_5cfe.jpg';
import cliente6 from '../assets/img/clientes/cliente_6unam.jpg';
import cliente7 from '../assets/img/clientes/cliente_7cetys.jpg';
import cliente8 from '../assets/img/clientes/cliente_8uabc.jpg';
import cliente9 from '../assets/img/clientes/cliente_9ibero.jpg';
import cliente10 from '../assets/img/clientes/cliente_10tec.jpg';
import cliente11 from '../assets/img/clientes/cliente_11imss.jpg';
import cliente12 from '../assets/img/clientes/cliente_12almater.jpg';
import cliente13 from '../assets/img/clientes/cliente_13hf.jpg';
import cliente14 from '../assets/img/clientes/cliente_14skyworks.jpg';
import cliente15 from '../assets/img/clientes/cliente_15cemex.jpg';
import cliente16 from '../assets/img/clientes/cliente_16fanosa.jpg';
import cliente17 from '../assets/img/clientes/cliente_17bachoco.jpg';
import cliente18 from '../assets/img/clientes/cliente_18apasco.jpg';
import cliente19 from '../assets/img/clientes/cliente_19ascotech.jpg';
import cliente20 from '../assets/img/clientes/cliente_20newell.jpg';
import '../assets/css/ClientesHome.css';

class ClientesHome extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };
    return (
      <div className="clientesHome">
        <div className="content">
          <h2>Clientes</h2>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <Slider {...settings}>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente1} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente2} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente3} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente4} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente5} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente6} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente7} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente8} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente9} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente10} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente11} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente12} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente13} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente14} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente15} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente16} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente17} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente18} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente19} />
                  </div>
                </div>
                <div className="cliente">
                  <div className="img">
                    <img src={cliente20} />
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ClientesHome;
