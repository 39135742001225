import React, { Component } from 'react';
import ContentContacto from './ContentContacto'

class Contacto extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="body">
        <div className="contact">
          <ContentContacto />
        </div>
      </div>
    );
  }
}

export default Contacto;
