import React, { Component } from 'react';
import Slider from "react-slick";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { MDBInput } from "mdbreact";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import correoExito from '../assets/img/icn_sent.png';
import correoError from '../assets/img/icn_failed.png';
// import { Carousel } from 'react-bootstrap';

// Prod
import prod1 from '../assets/img/banner/img_fisher.png';
import prod2 from '../assets/img/banner/img_condat.png';
import prod3 from '../assets/img/banner/img_ecocell22.png';
import prod4 from '../assets/img/banner/img_ecoshel9082.png';
import prod5 from '../assets/img/banner/img_incucell55.png';
import prod6 from '../assets/img/banner/img_ecocell55.png';
import prod7 from '../assets/img/banner/img_ultracongelador.png';

// Logos
import logoFisher from '../assets/img/banner/img_logo_fisher.svg';
import logoConcat from '../assets/img/banner/img_logo_condat.svg';

import '../assets/css/Banner.css';

class Banner extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      asunto: '',
      exito: false,
      error: false
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(asunto) {
    this.setState({ show: true });
    this.setState({ asunto: asunto });
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('./sendMail.php', {
      method: 'POST',
      body: data,
    })
    .then((response) => {
      console.log(response.status);
      if(response.status == 200){
        this.setState({ show: false });
        this.setState({ exito: true });
        setTimeout(() =>{ this.setState({ exito: false }); }, 3000);
      }
      else{
        this.setState({ show: false });
        this.setState({ error: true });
        setTimeout(() =>{ this.setState({ error: false }); }, 3000);
        document.getElementById("form").reset();
      }
    })
    .catch((error) => {
      console.log('Hubo un problema con la petición Fetch:' + error.message);
      this.setState({ show: false });
      this.setState({ error: true });
      setTimeout(() =>{ this.setState({ error: false }); }, 3000);
    });
  }

  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true
    };
    return (
      <div className="banners">
          <Slider {...settings} className="slider">
            <div className="banner banner1">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1>¿Tienes problemas de <span>coloración en el agua?</span></h1>
                    <p>¡Entonces tienes problemas de metales en el agua! Nosotros tenemos la solución. Obtén los beneficios del tratamiento de aguas Carus™ 2350. Te ayudamos a cumplir con las normas de certificación ANSI / NSF 60</p>
                    <Button variant="primary" onClick={() => this.handleShow('¿Tienes problemas de coloración en el agua?')}>Contáctanos para más informes</Button>
                  </Col>
                  {/* <Col xs="12" md="5" className="img" order="1">
                    <img src={img1} />
                  </Col> */}
                </Row>
              </div>
            </div>
            <div className="banner banner2">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1>Somos distribuidores <span>autorizados </span><img src={logoFisher}/></h1>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis enim justo. Sed at dui semper, pretium augue suscipit, pharetra nisl.</p> */}
                    <Button variant="primary" className="btnWhite" href="https://www.fishersci.com/us/en/home.html" target="_blank">Conoce más aquí</Button>
                  </Col>
                  <Col xs="12" md={{span:5, offset:1}} className="img" order="1">
                    <img src={prod1} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="banner banner4">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1>Somos distribuidores <span>autorizados </span><img src={logoConcat}/></h1>
                    <p>Refrigerantes para metalmecánica de fluidos</p>
                    <Button variant="primary" href="https://www.condat-lubricants.com/" target="_blank">Conoce más aquí</Button>
                  </Col>
                  <Col xs="12" md={{span:5, offset:1}} className="img" order="1">
                    <img src={prod2} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="banner banner2">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1><span>Incubadora Ecoshel </span> precio especial</h1>
                    <p>Incubadora de control digital:<br/>- Capacidad 80 L.<br/>- Temperatura ambiente +5 - 65 ºC.<br/>- Sensibilidad / estabilidad de temp.: 0.1 ºC / ± 0.5 ºC.<br/>- Exterior de acero esmaltado resistente a la corrosión.<br/>- Interior de acero inoxidable resistente a ácidos y álcalis.</p>
                    <Button variant="primary" className="btnWhite" onClick={() => this.handleShow('Incubadora Ecoshel precio especial')}>Contáctanos</Button>
                  </Col>
                  <Col xs="12" md={{span:5, offset:1}} className="img" order="1">
                    <img src={prod4} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="banner banner3">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1><span>Incubadora Incucell 55</span> precio especial</h1>
                    <p>Incubadora de laboratorio:<br/>- Volumen interior de 55 L.<br/>- Rango de temperaturas desde 5 ºC sobre temperatura ambiente hasta 100 ºC.<br/>- Funcionamiento silencioso y una circulación de aire suave en el espacio útil.</p>
                    <Button variant="primary" onClick={() => this.handleShow('Incubadora Incucell 55 precio especial')}>Contáctanos</Button>
                  </Col>
                  <Col xs="12" md={{span:5, offset:1}} className="img" order="1">
                    <img src={prod5} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="banner banner2">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1><span>Ultracongelador </span> a precio especial</h1>
                    <p>Congelador vertical de laboratorio:<br/>- Unidades de -20 ºC.<br/>- Brinda estabilidad superior a la temperatura y protección del producto.<br/>- Controlador de temperatura con microprocesador, su pantalla digital permite un ajuste preciso de la temperatura, mantenimiento y recuperación.</p>
                    <Button variant="primary" className="btnWhite" onClick={() => this.handleShow('Ultracongelador a precio especial')}>Contáctanos</Button>
                  </Col>
                  <Col xs="12" md={{span:5, offset:1}} className="img" order="1">
                    <img src={prod7} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="banner banner5">
              <div className="content body">
                <Row>
                  <Col xs="12" md="6" order="2">
                    <h1>Conoce nuestros <span>químicos industriales</span></h1>
                    <p>Manejamos productos químicos industriales como, ácido muriático, ácido nítrico, ácido fosfórico, ácido sulfúrico, entre otros.</p>
                    <Link to="/Catalogos" onClick={this.color}><Button variant="primary">Conoce más aquí</Button></Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Slider>
          <Modal show={this.state.show} onHide={this.handleClose} size="lg" centered className="modal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body closeButton>
              <h3>Si deseas más información</h3>
              <h4>¡envíanos un mensaje!</h4>
              <Form onSubmit={this.handleSubmit} id="form">
                <Row>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="text" label="Nombre" name="nombre" id="nombre" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="email" label="Correo" name="correo" id="correo" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="tel" label="Teléfono" name="tel" id="tel" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12" md="6">
                    <Form.Group>
                      <MDBInput type="text" label="Asunto" name="asunto" id="asunto" value={this.state.asunto} required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <Form.Group>
                      <MDBInput type="textarea" rows="3" label="Mensaje" name="mensaje" required/>
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <Form.Group>
                      <div className="g-recaptcha" data-sitekey="6LcIgscUAAAAAB_gtX5FMnQcupu9s0cHmdhqTiip"></div>
                    </Form.Group>
                  </Col>
                  <div class="pot">
                    <MDBInput type="text" label="website" name="website" id="website"/>
                  </div>
                </Row>
                <MDBInput type="hidden" name="contacto" value="contacto"/>
                <Button variant="primary" type="submit">Enviar</Button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.exito} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoExito} />
            <h3>¡Gracias!</h3>
            <p>Tu mensaje ha sido enviado</p>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.error} size="md" centered className="modal popup">
          <Modal.Body>
            <img className="iconSobre" src={correoError} />
            <h3>Lo sentimos</h3>
            <p>Ha ocurrido un problema al intentar enviar tu correo, inténtalo más tarde</p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Banner;
