import React, { Component } from 'react';
import '../assets/css/Home.css';

import Header from './Header'
import Banner from './Banner'
import NosotrosHome from './NosotrosHome'
import CotizaHome from './CotizaHome'
import ClientesHome from './ClientesHome'
import ContentContacto from './ContentContacto'

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <Banner/>
        <NosotrosHome/>
        <CotizaHome/>
        <ClientesHome/>
        <ContentContacto/>
      </div>
    );
  }
}

export default Home;
