import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import marca1 from '../assets/img/marcas/logo_marca1.jpg';
import marca2 from '../assets/img/marcas/logo_marca2.jpg';
import marca3 from '../assets/img/marcas/logo_marca3.jpg';
import marca4 from '../assets/img/marcas/logo_marca4.jpg';
import marca5 from '../assets/img/marcas/logo_marca5.jpg';
import marca6 from '../assets/img/marcas/logo_marca6.jpg';
import marca7 from '../assets/img/marcas/logo_marca7.jpg';
import marca8 from '../assets/img/marcas/logo_marca8.jpg';
import marca9 from '../assets/img/marcas/logo_marca9.jpg';
import marca10 from '../assets/img/marcas/logo_marca10.jpg';
import marca11 from '../assets/img/marcas/logo_marca11.jpg';
import marca12 from '../assets/img/marcas/logo_marca12.jpg';
import marca13 from '../assets/img/marcas/logo_marca13.jpg';
import marca14 from '../assets/img/marcas/logo_marca14.jpg';
import marca15 from '../assets/img/marcas/logo_marca15.jpg';
import marca16 from '../assets/img/marcas/logo_marca16.jpg';
import marca17 from '../assets/img/marcas/logo_marca17.jpg';
import marca18 from '../assets/img/marcas/logo_marca18.jpg';
import marca19 from '../assets/img/marcas/logo_marca19.jpg';

import '../assets/css/Nosotros.css';

class NosotrosHome extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 6,
      slidesToScroll: 6,
      autoplay: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
    return (
      <div className="nosotros">
        <div className="content">
          <Row>
            <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
              <h2>Nosotros</h2>
              <hr/>
              <p>Nuestra empresa, fundada en 2002, se especializa en la comercialización de productos químicos, material para laboratorio, venta y servicio de bombas dosificadoras (líquidos y gases), venta de equipo y material para albercas, con esto queremos cubrir las necesidades de nuestros clientes, así como dar un servicio integral ayudando en la mejora continua de sus procesos.</p>
              <Link to="/Nosotros"><Button variant="primary">Ver más</Button></Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Slider {...settings}>
                <div className="marcas">
                  <img src={marca1} />
                </div>
                <div className="marcas">
                  <img src={marca2} />
                </div>
                <div className="marcas">
                  <img src={marca3} />
                </div>
                <div className="marcas">
                  <img src={marca4} />
                </div>
                <div className="marcas">
                  <img src={marca5} />
                </div>
                <div className="marcas">
                  <img src={marca6} />
                </div>
                <div className="marcas">
                  <img src={marca7} />
                </div>
                <div className="marcas">
                  <img src={marca8} />
                </div>
                <div className="marcas">
                  <img src={marca9} />
                </div>
                <div className="marcas">
                  <img src={marca10} />
                </div>
                <div className="marcas">
                  <img src={marca11} />
                </div>
                <div className="marcas">
                  <img src={marca12} />
                </div>
                <div className="marcas">
                  <img src={marca13} />
                </div>
                <div className="marcas">
                  <img src={marca14} />
                </div>
                <div className="marcas">
                  <img src={marca15} />
                </div>
                <div className="marcas">
                  <img src={marca16} />
                </div>
                <div className="marcas">
                  <img src={marca17} />
                </div>
                <div className="marcas">
                  <img src={marca18} />
                </div>
                <div className="marcas">
                  <img src={marca19} />
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default NosotrosHome;
