import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import carrito from '../assets/img/icn_cart_grey.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactSearchBox from 'react-search-box';
import * as actualizarCarrito from '../redux/actions/actualizarCarrito'
import * as totalCarrito from '../redux/actions/totalCarrito';
import '../assets/css/Cotiza.css';
import Footer from './Footer'
import { MDBInput } from "mdbreact";


class Cotiza extends Component {

  // constructor(props, context) {
  //   super(props, context);
  //   this.state = {
  //     data: [],
  //   };
  // }
    
  cotizar = (codigo, descripcion, precio, imagen) => {
    const datos = {
      codigo: codigo,
      descripcion: descripcion,
      precio: precio,
      imagen: imagen,
      cantidad: 1
    }
    this.props.actualizarCarrito(datos);
    setTimeout(() => { this.cantidadTotal(); }, 200);
  }

  cantidadTotal = () => {
    const { resultCarrito } = this.props;
    var total = 0
    resultCarrito.map((producto, index) => {
        total += parseInt(producto.cantidad)
    })
    this.props.totalCarrito(total);
  }
  componentDidMount(){
    window.scrollTo(0, 0)

    
  }
  onChange(){
    console.log("hola")
  }
  render() {
    const json = require('./dlab.json');
    const { resultCarrito } = this.props;
    
    var data = []
    json.map((producto, index) => {
      data.push(
        {
          key: producto.codigo,
          value: producto.descripcion,
          producto: producto
        }
      )
    })
    return (
      <div className="body">
        <div className="cotiza">
          <div className="content">
            <Row>
              <Col xs="12" md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2>Productos de Limitada Existencia en Stock</h2>
                <ReactSearchBox
                  label="Asunto"
                  className="form-control"
                  placeholder="Ingresa tu búsqueda aquí"
                  value="Doe"
                  data={data}
                  callback={record => console.log(record)}
                  onSelect={record => this.props.history.push("/Producto/"+record.producto.codigo)}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col xs="12" md="4" lg="3" className="filtros">
                <h3>Haz una cotización</h3>
                <Card>
                  <Card.Header>
                    <strong>Categoría</strong>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>Categoría 1</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Categoría 2</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Categoría 3</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Categoría 4</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Categoría 5</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>
                    <strong>Marca</strong>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>Marca 1</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Marca 2</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Marca 3</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Marca 4</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Marca 5</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>
                    <strong>Producto</strong>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>Producto 1</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Producto 2</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Producto 3</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Producto 4</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Producto 5</Form.Label>
                      <Form.Check aria-label="option 1" />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col> */}
              {/* <Col xs="12" md="8" lg={{ span: 8, offset: 1 }} > */}
              <Col xs="12" >
                {/* <Row>
                  <Col xs="12" className="ordenar">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Filtrar por:</Form.Label>
                        <Form.Control as="select">
                          <option>Orden de (A-Z)</option>
                          <option>Orden de (A-Z)</option>
                          <option>Orden de (A-Z)</option>
                          <option>Orden de (A-Z)</option>
                          <option>Orden de (A-Z)</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row> */}
                <Row>
                {/* {resultCarrito.map((carrito, index) => {
                    return(
                      <strong key={"carrito"+carrito.codigo}>{carrito.codigo}</strong>
                    )
                  })} */}
                {json.map(producto => {
                  var car = "";
                  const prodImage = require(`../assets/img/productos/dlab/${producto.imagen}`);
                  return (    
                    <Col xs="6" md="3" className="productos" key={producto.codigo}>
                      <div className="img">
                        <Link to={"/Producto/"+producto.codigo}>
                          <img src={prodImage} className="imgProd"/>
                        </Link>
                        {resultCarrito.map((p, index) => {
                          if(producto.codigo == p.codigo){
                            car = "carroActive"
                          }
                        })}   
                          <Button variant="" className={"carro "+ car} onClick={() => this.cotizar(producto.codigo, producto.descripcion, producto.precio, producto.imagen)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                            <path id="ic_shopping_cart_24px" d="M8.8,22.8a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,8.8,22.8ZM1,2V4.6H3.6l4.68,9.867L6.525,17.652A2.513,2.513,0,0,0,6.2,18.9a2.608,2.608,0,0,0,2.6,2.6H24.4V18.9H9.346a.322.322,0,0,1-.325-.325l.039-.156L10.23,16.3h9.685a2.588,2.588,0,0,0,2.275-1.339l4.654-8.437A1.27,1.27,0,0,0,27,5.9a1.3,1.3,0,0,0-1.3-1.3H6.473L5.251,2H1ZM21.8,22.8a2.6,2.6,0,1,0,2.6,2.6A2.6,2.6,0,0,0,21.8,22.8Z" transform="translate(-1 -2)" fill="#d5d5d5"/>
                          </svg>
                          </Button>
                      </div>
                      <strong>{producto.descripcion}</strong>
                      <p>{producto.precio}</p>
                    </Col>
                  )
                })} 
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}


const mapStateToProps = state => ({
    resultCarrito: state.items,
});

const mapDispatchToProps = dispatch =>
bindActionCreators({ ...actualizarCarrito, ...totalCarrito}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cotiza);
