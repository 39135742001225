import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import logo from '../assets/img/logo_quiasa.png';
import latam from '../assets/img/logo_latamscientific.png';
import quimes from '../assets/img/logo_quimes.png';
import carrito from '../assets/img/icn_cart_blue.svg';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../assets/css/Header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: 'header'
    }
  }
  listenScrollEvent = e => {
    if (window.scrollY <= 10) {
      var pathname = window.location.hash;
      const mq = window.matchMedia('(max-width: 991px)')
      if(pathname == '#/'){
        if(!mq.matches) {
          this.setState({background: 'header transparent'})
        }
      }
    } else {
      this.setState({background: 'header'})
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    var pathname = window.location.hash;
    const mq = window.matchMedia('(max-width: 991px)')
    if(pathname == '#/'){
      if(!mq.matches) {
        this.setState({background: 'header transparent'})
      }
    }
  }
  color = e => {
    var pathname = window.location.hash;
    if(pathname == '#/'){
      this.setState({background: 'header'})
    }
    const mq = window.matchMedia('(max-width: 991px)')
    if(mq.matches) {
      setTimeout(function(){ window.location.reload(); }, 50);
    }
  }
  transparent = e => {
    var pathname = window.location.hash;
    const mq = window.matchMedia('(max-width: 991px)')
    if(pathname != '#/'){
      if(!mq.matches) {
        this.setState({background: 'header transparent'})
      }
    }
  }
  
  render() {
    const { resultCarrito } = this.props;
    return (
      <div className={this.state.background} >
        <div className="content">
          <Navbar variant="dark" expand="lg">
            <Navbar.Brand to="/" as={Link} onClick={this.transparent}>
              <img src={logo} className="d-inline-block align-top logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="toggle"/>
            <Nav.Link as={NavLink} to="/Carrito" onClick={this.color} activeClassName='navActive' className="mobileCarrito"><img src={carrito} /><div className="totalCarrito">{resultCarrito}</div></Nav.Link>
            <Navbar.Collapse id="basic-navbar-nav" >
              <Nav>
                <Nav.Link as={NavLink} to="/Nosotros" onClick={this.color} activeClassName='navActive'>Nosotros</Nav.Link>
                <Nav.Link as={NavLink} to="/Cotiza" onClick={this.color} activeClassName='navActive'>Cotiza aquí</Nav.Link>
                <Nav.Link as={NavLink} to="/Catalogos" onClick={this.color} activeClassName='navActive'>Catálogos</Nav.Link>
                <Nav.Link as={NavLink} to="/Contacto" onClick={this.color} activeClassName='navActive'>Contacto</Nav.Link>
                <Nav.Link href="https://www.latamscientific.com/" target="_blank" className="latam"><img src={latam} /></Nav.Link>
                <Nav.Link href="http://quimes.com.mx/" target="_blank" className="quimes"><img src={quimes} /></Nav.Link>
                <Nav.Link as={NavLink} to="/Carrito" onClick={this.color} activeClassName='navActive' className="desktopCarrito"><img src={carrito} /><div className="totalCarrito">{resultCarrito}</div></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    resultCarrito: state.carrito,
  };
};

export default connect(mapStateToProps)(Header);
